.fundBanner{
    background-image: url("../../asset/fundBanner.png");
    
        background-size: cover;
        height: 670px;
        background-position: center;
}

@media screen and (max-width: 500px) {
    .fundBanner {
            background-image: url("../../asset/fundBanner.png");
    
            background-size: cover;
            height: 500px;
            background-position: center;
        }
}