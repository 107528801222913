.homeBanner{
    background-image: url("../../asset/mainVideo.gif");
    background-position: center;
    background-size: cover;
    height: 700px;
  
}

@media screen and (max-width: 500px) {
.homeBanner {
     
        height: 530px;
       
    }
}