.detailsBanner {


    background-size: cover;
    height: 670px;
    background-position: center;
}
@media screen and (max-width: 500px) {
    .detailsBanner {

        height: 500px;

    }
}