@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GilroyBold";
    src: local("GilroyBold"),
        url("./webfonts/Gilroy-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "GilroyRegular";
    src: local("GilroyRegular"),
        url("./webfonts/Gilroy-Regular.ttf") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "GilroyMedium";
    src: local("GilroyMedium"),
        url("./webfonts/Gilroy-Medium.ttf") format("truetype");
    font-weight: bold;
}
